module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Codected","short_name":"Codected","start_url":"/","background_color":"#fff","icon":"src/assets/images/favicon-32x32.png","icons":[{"src":"src/assets/images/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"src/assets/images/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/assets/images/apple-touch-icon.png","sizes":"180x180","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"22fe32d9d7d1b1c3cafa471e455173ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-D8Q5X4JZ0V"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"202455021681484"},
    }]
