exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oferta-strony-internetowe-js": () => import("./../../../src/pages/oferta/strony-internetowe.js" /* webpackChunkName: "component---src-pages-oferta-strony-internetowe-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-przeslano-formularz-js": () => import("./../../../src/pages/przeslano-formularz.js" /* webpackChunkName: "component---src-pages-przeslano-formularz-js" */),
  "component---src-pages-realizacje-ekowkra-js": () => import("./../../../src/pages/realizacje/ekowkra.js" /* webpackChunkName: "component---src-pages-realizacje-ekowkra-js" */),
  "component---src-pages-realizacje-flamecenter-js": () => import("./../../../src/pages/realizacje/flamecenter.js" /* webpackChunkName: "component---src-pages-realizacje-flamecenter-js" */),
  "component---src-pages-realizacje-index-js": () => import("./../../../src/pages/realizacje/index.js" /* webpackChunkName: "component---src-pages-realizacje-index-js" */),
  "component---src-pages-realizacje-mstudio-kunert-js": () => import("./../../../src/pages/realizacje/mstudio-kunert.js" /* webpackChunkName: "component---src-pages-realizacje-mstudio-kunert-js" */),
  "component---src-pages-realizacje-speedcopy-js": () => import("./../../../src/pages/realizacje/speedcopy.js" /* webpackChunkName: "component---src-pages-realizacje-speedcopy-js" */),
  "component---src-pages-realizacje-stinger-js": () => import("./../../../src/pages/realizacje/stinger.js" /* webpackChunkName: "component---src-pages-realizacje-stinger-js" */),
  "component---src-pages-realizacje-szkolka-leszka-js": () => import("./../../../src/pages/realizacje/szkolka-leszka.js" /* webpackChunkName: "component---src-pages-realizacje-szkolka-leszka-js" */)
}

